// src/components/Inbox/ChatHistory.jsx

import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Card, CardContent, Typography, IconButton, Tooltip, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import backend from "../../../../api";
import { useNavigate } from "react-router-dom";
import { setChatHistoryId } from "../../../../redux-store/auth/actions";
import DeleteIcon from '@mui/icons-material/Delete';
import ChatIcon from '@mui/icons-material/Chat';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { format } from 'date-fns';
import styled from "styled-components";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

const HistoryContainer = styled.div`
  padding: 20px;
  background-color: #ffffff;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ChatCard = styled(motion.div)`
  margin-bottom: 16px;
`;

const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-4px);
  }
`;

const ChatInfo = styled.div`
  flex: 1;
  cursor: pointer;
`;

const ChatActions = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  color: #f44336;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #d32f2f;
  }
`;

const ChatHistoryButton = styled(Button)`
  width: 100%;
  margin-bottom: 20px !important;
  background: linear-gradient(45deg, #007bff, #6610f2) !important;
  color: #ffffff !important;
  border-radius: 12px !important;
  padding: 10px 20px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  transition: background 0.3s, transform 0.3s;

  &:hover {
    background: linear-gradient(45deg, #0056b3, #4b0082) !important;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

function ChatHistory() {
  const [data, setData] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  
  const _id = user._id;
  const projectId =  localStorage.getItem("projectID")
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const getChats = async () => {
      
      const UserId = user._id;
      setLoading(true)
      try {
        const response = await backend.get("/chats");
        // Filter the response data to include only the required objects
        const filteredChats = response?.data?.data?.filter(
          (item) => item.UserId === UserId ||item.SellerId === UserId  && item.chat.length > 0
        );
        // setLoading(false);
        // console.log(filteredChats);
        setData(filteredChats);
        console.log(response);
        setLoading(false)
      } catch (error) {
        console.error(error);
        // toast.error("Error saving story");
      }
    };
    getChats();
  }, [projectId]);

  const OpenChatHistory = async(id) => {
    await dispatch(setChatHistoryId(id));
    navigateTo(`/inbox/${id}`);
    // window.location.reload();
  };

  const saveChat = async () => {
    const userId = user._id;
    setLoading(true);
    try {
      const body = {
        UserId: userId,
        projectId: "test",
      };

      const response = await backend.post("/chats", body);
      if (response) {
        const RouteID = response?.data?.data?._id;
        if (RouteID) {
          await dispatch(setChatHistoryId(RouteID));
          navigateTo(`/inbox/${RouteID}`);
        }
      }
    } catch (error) {
      console.error("Error saving chat:", error);
      toast.error("Failed to create new chat.");
    } finally {
      setLoading(false);
    }
  };

  const DeleteChat = async (id) => {
    const UserId = user._id;
    setLoading(true);
    try {
      await backend.delete(`/chats/${id}`);
      const updatedChats = data.filter(
        (item) => !(item.UserId === UserId || item.SellerId === UserId) || item._id !== id
      );
      setData(updatedChats);
      toast.success("Chat deleted successfully.");
    } catch (error) {
      console.error("Failed to delete chat:", error);
      toast.error("Failed to delete chat.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <HistoryContainer>
      {/* <ChatHistoryButton
        variant="contained"
        startIcon={<ChatIcon />}
        onClick={()=>saveChat()}
      >
        Start New Chat
      </ChatHistoryButton> */}

      {loading ? (
        <div className="loading-spinner">
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Grid container spacing={2}>
          {data.length > 0 ? (
            data.map((chat) => (
              <Grid item xs={12} key={chat._id}>
                <ChatCard
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <StyledCard>
                    <ChatInfo onClick={() => OpenChatHistory(chat._id)}>
                      <Typography variant="h6" color="textPrimary">
                        {user.role === "buyer" ? chat.sellerName : chat.buyerName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {format(new Date(chat.createdAt), 'dd MMM yyyy')}
                      </Typography>
                    </ChatInfo>
                    <ChatActions>
                      <Tooltip title="Delete Chat">
                        <StyledDeleteIcon onClick={() => DeleteChat(chat._id)} />
                      </Tooltip>
                    </ChatActions>
                  </StyledCard>
                </ChatCard>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary" align="center">
                No chat history available.
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </HistoryContainer>
  );
}

export default ChatHistory;
