// Menu.jsx

import React, { useState, useEffect } from "react";
import "./Menu.css";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../redux-store";
import AuthPopup from "./auth-pop/Popup";

const Menu = () => {
  const { user } = useSelector((state) => state.auth);
  const ProjectID = localStorage.getItem("projectID");

  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  // Handle opening the authentication popup
  const handleOpenAuth = () => {
    setOpen(true);
    setIsOpen(false);
  };

  // Handle closing the authentication popup
  const handleCloseAuth = () => {
    setOpen(false);
  };

  // Toggle the mobile menu
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // Close the mobile menu
  const handleClose = () => {
    setIsOpen(false);
  };

  // Navigate to the dashboard and close the menu
  const navigateToDashboard = () => {
    navigateTo(`/dashboard/`);
    setIsOpen(false);
  };

  // Handle user logout
  const handleLogout = async () => {
    localStorage.clear();
    navigateTo("/");
    await dispatch(authActions.logout());
    toast.success("Signed out successfully");
    setIsOpen(false);
  };

  // Close the menu when the route changes
  // useEffect(() => {
  //   const closeMenu = () => setIsOpen(false);
  //   navigateTo.listen(closeMenu);
  //   return () => {
  //     navigateTo.unlisten(closeMenu);
  //   };
  // }, [navigateTo]);

  return (
    <div className="menu-wrapper">
      {/* Hamburger Menu Icon */}
      <button
        className={`menu-icon ${isOpen ? "open" : ""}`}
        onClick={handleToggle}
        aria-label={isOpen ? "Close menu" : "Open menu"}
      >
        {isOpen ? <CloseIcon className="close-icon" /> : <MenuIcon className="menu-icon-svg" />}
      </button>

      {/* Mobile Navigation Menu */}
      <nav className={`menu-navigation ${isOpen ? "open" : ""}`} aria-hidden={!isOpen}>
        <ul>
          <li>
            <a href="/" onClick={handleClose} className="menu-link">
              Home
            </a>
          </li>
          <li>
            <a href="/About" onClick={handleClose} className="menu-link">
              Features
            </a>
          </li>
          <li>
            <a href="/pricing" onClick={handleClose} className="menu-link">
              Pricing
            </a>
          </li>
          <li>
            <a href="/#faq" onClick={handleClose} className="menu-link">
              FAQ
            </a>
          </li>
          <li>
            {user ? (
              <a onClick={navigateToDashboard} className="menu-link">
                Dashboard
              </a>
            ) : null}
          </li>
          {user && (
            <li>
              <a onClick={handleLogout} className="menu-link">
                Logout
              </a>
            </li>
          )}
        </ul>
      </nav>

      {/* Overlay to darken the background when menu is open */}
      {isOpen && <div className="menu-overlay" onClick={handleClose} aria-hidden="true"></div>}

      {/* Authentication Popup */}

      {/* Toast Notifications */}
      <ToastContainer />
    </div>
  );
};

export default Menu;
