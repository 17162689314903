// MonetizationStrategy.jsx

import React from "react";
import "./Monitization.css"; // Ensure this CSS file is correctly imported
import { motion } from "framer-motion"; // For animations
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PremiumIcon from '@mui/icons-material/Star';
import PartnershipIcon from '@mui/icons-material/Handshake';
import CommissionIcon from '@mui/icons-material/AttachMoney';
import DataInsightsIcon from '@mui/icons-material/DataUsage';

function MonetizationStrategy() {
  return (
    <div className="monetization-container">
      <motion.h1
        className="monetization-title"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Monetization Strategy
      </motion.h1>

      <motion.p
        className="monetization-description"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 1 }}
      >
        MayaIQ will offer our AI assistant services at a fraction of the cost compared to other providers. We achieve this by leveraging multiple income sources, allowing us to keep prices low while delivering high-quality services. This strategy ensures advanced AI technology is accessible to businesses of all sizes.
      </motion.p>

      <motion.h2
        className="monetization-subtitle"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 1, duration: 0.8 }}
      >
        Multiple Income Sources:
      </motion.h2>

      <div className="income-sources">
        <motion.div
          className="income-source"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 1.2, duration: 0.6 }}
        >
          <SubscriptionsIcon className="source-icon" />
          <h3>Subscriptions</h3>
          <p>Offering tiered subscription plans to cater to different business needs.</p>
        </motion.div>

        <motion.div
          className="income-source"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 1.4, duration: 0.6 }}
        >
          <PremiumIcon className="source-icon" />
          <h3>Premium Features</h3>
          <p>Providing advanced features for premium users to enhance their experience.</p>
        </motion.div>

        <motion.div
          className="income-source"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 1.6, duration: 0.6 }}
        >
          <PartnershipIcon className="source-icon" />
          <h3>Partnerships</h3>
          <p>Collaborating with other businesses to expand our service offerings.</p>
        </motion.div>

        <motion.div
          className="income-source"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 1.8, duration: 0.6 }}
        >
          <CommissionIcon className="source-icon" />
          <h3>Commissions</h3>
          <p>Earning commissions from referrals and integrations with other platforms.</p>
        </motion.div>

        <motion.div
          className="income-source"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 2.0, duration: 0.6 }}
        >
          <DataInsightsIcon className="source-icon" />
          <h3>Compliant Data Insights</h3>
          <p>Offering valuable data insights while ensuring compliance with data protection regulations.</p>
        </motion.div>
      </div>
    </div>
  );
}

export default MonetizationStrategy;
