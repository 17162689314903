// src/components/FAQs/FAQs.jsx

import React, { useEffect, useRef } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { motion } from 'framer-motion';
import "./FAQs.css"; // Custom styles

const FAQs = () => {
  const sectionRef = useRef(null);

  const faqData = [
    {
      question: '1. What is MayaIQ?',
      answer: `MayaIQ is a 24/7 AI assistant that connects businesses and clients by automating and enhancing their interactions, such as negotiations and customer support, across various industries.`,
    },
    {
      question: '2. Who can use MayaIQ?',
      answer: `MayaIQ is designed for both businesses and clients across multiple sectors.

• **For Businesses:** Companies in industries like micro-influencer marketing, hospitality, construction, and beauty can utilize MayaIQ to automate tasks, streamline operations, and connect with potential clients more efficiently.

• **For Clients:** Individuals seeking services or products in these industries can use MayaIQ to find businesses that meet their needs, negotiate deals, and receive personalized assistance anytime.`,
    },
    {
      question: '3. Why should someone invest in MayaIQ?',
      answer: `Investing in MayaIQ offers the opportunity to be part of an innovative platform that leverages advanced AI technology to revolutionize business operations and client interactions. Key reasons to consider investing include:

• **Innovative AI Technology:** MayaIQ utilizes cutting-edge AI to automate and enhance interactions, addressing a growing demand for AI-driven solutions.

• **Dual Market Approach:** Serving both businesses and clients allows for multiple revenue streams and increased market engagement.

• **Scalable Business Model:** The platform is designed for rapid growth without proportional increases in operational costs.

• **Market Potential:** Focus on high-growth industries with significant opportunities for technological disruption.`,
    },
    {
      question: '4. Who can follow our social media channels and subscribe to our newsletters?',
      answer: `Anyone interested in staying updated on the latest developments in AI technology, business automation, and industry trends is welcome to follow our social media channels and subscribe to our newsletters. This includes:

• **Business Professionals:** Entrepreneurs, managers, and decision-makers looking for innovative solutions to enhance their operations.

• **Investors:** Individuals or entities interested in investment opportunities within the AI and technology sectors.

• **Clients and Consumers:** Those seeking improved services and personalized interactions with businesses across our focus industries.

• **Tech Enthusiasts:** Anyone keen on following advancements in AI and its applications in various industries.`,
    },
    {
      question: '5. How does MayaIQ ensure data security and compliance?',
      answer: `MayaIQ prioritizes data security and compliance by implementing robust measures to protect sensitive information:

• **Regulatory Compliance:** Adheres to international data protection regulations such as GDPR and CCPA to ensure user data is handled responsibly.

• **Advanced Security Measures:** Employs encryption, secure authentication protocols, and regular security audits to safeguard data integrity and privacy.

• **Access Controls:** Utilizes role-based access and strict permission settings to prevent unauthorized access within the platform.

• **Transparency:** Maintains clear privacy policies and provides users with control over their data and interaction preferences.`,
    },
    // ... Add more FAQs as needed
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Add animation class when the section is in the viewport
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-fade-in');
        }
      },
      {
        root: null, // relative to the viewport
        rootMargin: '0px',
        threshold: 0.1, // trigger the callback when 10% of the element is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section id='faq' ref={sectionRef} className='faq-section'>
      <Box
        component={motion.div}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="faq-container"
      >
        <Button
          variant="contained"
          sx={{
            background: 'linear-gradient(45deg, #00c6ff, #0072ff)',
            color: '#fff',
            borderRadius: '25px',
            padding: { xs: "0.5rem 1rem", md: "0.75rem 2rem" },
            fontWeight: "600",
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
            marginBottom: "1.5rem",
            '&:hover': {
              background: 'linear-gradient(45deg, #0056b3, #003f7f)',
              boxShadow: 'none',
            }
          }}
        >
          FAQs
        </Button>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            fontSize: { xs: '2rem', md: '2.5rem' },
            fontWeight: '700',
            color: "#00c6ff",
            marginBottom: '2rem',
            textAlign: 'center',
            textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)',
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Box sx={{ maxWidth: "900px", width: "100%", margin: "auto" }}>
          {faqData.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                backgroundColor: 'rgba(21, 12, 66, 0.85)', // Semi-transparent dark background
                color: '#ffffff',
                border: '1px solid #00c6ff',
                borderRadius: '10px',
                boxShadow: 'none',
                marginBottom: '1rem',
                '&:before': {
                  display: 'none',
                },
                '&.Mui-expanded': {
                  margin: 'auto',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#00c6ff' }} />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography sx={{ fontWeight: 600, fontSize: '1.1rem' }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: '#a0a0a0', whiteSpace: 'pre-line' }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </section>
  );
};

export default FAQs;
