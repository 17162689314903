// src/components/HomePage/HomePage.jsx

import React from 'react';
import SectionA from './Components/SectionA';
import Features from './Components/Features';
import FAQs from './Components/Faqs';
import ContactUs from '../../Global-Components/Contact/Contact';
import MonetizationStrategy from './Components/Monitization/Monitization';
import SellersCarousel from './Components/Sellers/Sellers';
import Assistant from './Components/Assistant-Home/Assistant-Home';
// import Testimonials from '../Testimonials/Testimonials'; // If created
// import Footer from '../Footer/Footer'; // If created

const HomeNew = () => {
  return (
    <div>
      <SectionA />
      <SellersCarousel/>
      <Features />
      <FAQs />
      <ContactUs/>
      {/* <Testimonials /> */}
      {/* <Footer /> */}
    </div>
  );
};

export default HomeNew;
