import React,{useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Menu from './Global-Components/Menu';
import Nav from './Global-Components/Nav';
import Footer from './Global-Components/Footer/Footer';
import ResponsiveDrawer from './Global-Components/Sidebar';
import Inbox from "./Pages/Account/Inbox/Inbox";
import HomeNew from './Pages/Home-New/Home-New';
import Sellers from './Pages/Users/Users';
import SellerAuth from './Pages/Auth/Seller';
import BuyerAuth from './Pages/Auth/Buyer';
import Assistant from './Pages/AI-Assistant/AI-Assistant';
import SellerDetails from './Pages/Users/Details';
import Account from './Pages/Profile/Account';
import Users from './Pages/Users/Users';
import MonetizationStrategy from './Pages/Home-New/Components/Monitization/Monitization';

const RenderRouterWithSideBar = (Component) => (
  <>
    <ResponsiveDrawer component={<Component />}/>
  </>
);

const RenderRouterWithMenuFooter = (Component) => (
  <>
  <Nav/>
   <Menu />
    <Component />
    <Footer />
  </>
);

const RenderRouterWithMenuOnly = (Component) => (
  <>
   <Menu />
    <Component />

  </>
);



const LoggedInRoutes = (role) => (
  <>
    <Routes>
      
    <Route path="/" element={RenderRouterWithSideBar(Users)} />
    <Route path="/inbox/:id/" element={RenderRouterWithSideBar(Inbox)} />
     <Route path="/inbox" element={RenderRouterWithSideBar(Inbox)} />
    <Route path="/home" element={RenderRouterWithMenuFooter(HomeNew)} />
    <Route path="/dashboard" element={RenderRouterWithSideBar(Users)} />
    {/* <Route path={role=="seller"?"/buyers":'/sellers'} element={RenderRouterWithSideBar(role=="buyer"?Buyers:Sellers)} /> */}
    <Route path="/assistant" element={RenderRouterWithSideBar(Assistant)} />
    <Route path="/sellers/" element={RenderRouterWithSideBar(Users)} />
    <Route path="/sellers/:id" element={<SellerDetails />} />
    <Route path="/account" element={RenderRouterWithSideBar(Account)} />



    <Route path="*" element={RenderRouterWithMenuFooter(HomeNew)} />
    </Routes>
  </>
);

const NotLoggedInRoutes = () => (
  <>

    <Routes>
    <Route path="/" element={RenderRouterWithMenuFooter(HomeNew)} />
    <Route path="/sellerauth" element={<SellerAuth />} />
    <Route path="/buyerauth" element={<BuyerAuth />} />
    <Route path="/sellers/:id" element={<SellerDetails />} />

    
    <Route path="/home" element={RenderRouterWithMenuFooter(HomeNew)} />
    <Route path="*" element={RenderRouterWithMenuFooter(HomeNew)} />
    <Route path="/pricing" element={RenderRouterWithMenuFooter(MonetizationStrategy)} />

    </Routes>

  </>
);

function AppRoutes() {
  const { user } = useSelector((state) => state.auth);
  const role = user?.role;

  return (
    <BrowserRouter>
      {user ? <LoggedInRoutes role={role}/> : <NotLoggedInRoutes />}
    </BrowserRouter>
  );
}

export default AppRoutes;
