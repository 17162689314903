// src/components/Inbox/Inbox.jsx

import React, { useState, useRef, useEffect } from "react";
import { Grid, CircularProgress } from "@mui/material";
import ChatBox from "./chatBox";
import ChatHistory from "./ChatHistory/ChatHistory";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import backend from "../../../api";
import { setResponseText, setEditorText } from "../../../redux-store/auth/actions";

const Container = styled.div`
  height: 100vh;
  overflow: hidden;
  background-color: #f0f2f5;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ChatBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

function Inbox() {
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const LoggedInUserRole = user?.role;
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch chats or perform other side effects if necessary
  }, []);

  if (loading) {
    return (
      <div className="loading-spinner">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Container>
      <Grid container spacing={2} style={{ height: "100%" }}>
        <Grid item xs={12} md={8} xl={9}>
          <ChatBox />
        </Grid>
        <Grid item xs={12} md={4} xl={3}>
          <ChatHistory />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Inbox;
