// src/components/Features/Features.jsx

import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SpeedIcon from '@mui/icons-material/Speed';
import ChatIcon from '@mui/icons-material/Chat'; // Additional icon for more features

// Importing Material-UI Icons
import HandshakeIcon from '@mui/icons-material/Handshake';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import BuildIcon from '@mui/icons-material/Build';

import "./Features.css"; // Custom styles

const featuresData = [
  {
    icon: <HandshakeIcon fontSize="large" className="feature-icon" />,
    title: 'AI-Driven Negotiation Engine',
    description: 'Automates price negotiations between businesses and clients using advanced AI algorithms. Enhances efficiency by reducing the need for direct human involvement in the negotiation process.',
  },
  {
    icon: <SupportAgentIcon fontSize="large" className="feature-icon" />,
    title: '24/7 Virtual AI Assistant',
    description: 'Maya provides continuous customer engagement and support at any time of day. Handles inquiries, provides information, and guides clients through purchasing decisions, improving customer satisfaction.',
  },
  {
    icon: <ConnectWithoutContactIcon fontSize="large" className="feature-icon" />,
    title: 'Dual AI Functionality Connecting Businesses and Clients',
    description: 'Acts as a bridge between businesses seeking clients and clients seeking services. Facilitates seamless connections across industries such as micro-influencer marketing, hospitality, construction, and beauty.',
  },
  {
    icon: <BuildIcon fontSize="large" className="feature-icon" />,
    title: 'Operational Efficiency through Task Automation',
    description: 'Automates routine tasks like data entry, appointment scheduling, and order processing. Streamlines operations, allowing businesses to focus on strategic initiatives and growth.',
  },
];


const Features = () => {
  return (
    <section id='features' className='features-section'>
      <Box
        component={motion.div}
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className="features-container"
      >
        <Typography
          variant="h4"
          component="h2"
          sx={{
            fontSize: { xs: '2rem', md: '2.5rem' },
            fontWeight: '700',
            color: "#00c6ff",
            marginBottom: '2rem',
            textAlign: 'center',
            textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)',
          }}
        >
          Our Features
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {featuresData.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box
                component={motion.div}
                className="feature-card"
                whileHover={{ scale: 1.05, boxShadow: '0 8px 30px rgba(0, 198, 255, 0.5)' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <Box className="icon-container">
                  {feature.icon}
                </Box>
                <Typography variant="h6" className="feature-title">
                  {feature.title}
                </Typography>
                <Typography variant="body1" className="feature-description">
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </section>
  );
};

export default Features;
