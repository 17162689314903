// SellersCarousel.jsx

import React from "react";
import "./Sellers.css";
import { Link } from "react-router-dom";

// Dummy data for sellers with random names
const sellers = [
  {
    id: 1,
    name: "Emma Johnson",
    image: "https://randomuser.me/api/portraits/women/1.jpg",
  },
  {
    id: 2,
    name: "Liam Smith",
    image: "https://randomuser.me/api/portraits/men/2.jpg",
  },
  {
    id: 3,
    name: "Olivia Brown",
    image: "https://randomuser.me/api/portraits/women/3.jpg",
  },
  {
    id: 4,
    name: "Noah Davis",
    image: "https://randomuser.me/api/portraits/men/4.jpg",
  },
  {
    id: 5,
    name: "Ava Miller",
    image: "https://randomuser.me/api/portraits/women/5.jpg",
  },
  {
    id: 6,
    name: "Elijah Wilson",
    image: "https://randomuser.me/api/portraits/men/6.jpg",
  },
  {
    id: 7,
    name: "Sophia Martinez",
    image: "https://randomuser.me/api/portraits/women/7.jpg",
  },
  {
    id: 8,
    name: "William Anderson",
    image: "https://randomuser.me/api/portraits/men/8.jpg",
  },
  // Add more sellers as needed
];

const SellersCarousel = () => {
  return (
    <section className="sellers-carousel-section">
      <h2 className="carousel-title">Our Trusted Sellers</h2>
      <div className="carousel-container">
        <div className="carousel-track">
          {sellers.map((seller) => (
            <Link to="/buyerauth" key={seller.id} className="carousel-item">
              <img src={seller.image} alt={seller.name} className="seller-image" />
              <p className="seller-name">{seller.name}</p>
            </Link>
          ))}
          {/* Duplicate the sellers array to create a seamless loop */}
          {sellers.map((seller) => (
            <Link to="/buyerauth" key={`${seller.id}-duplicate`} className="carousel-item">
              <img src={seller.image} alt={seller.name} className="seller-image" />
              <p className="seller-name">{seller.name}</p>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SellersCarousel;
