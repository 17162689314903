// ContactUs.jsx

import React from "react";
import "./Contact.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter"; // Using TwitterIcon for X
import EmailIcon from "@mui/icons-material/Email";

function ContactUs() {
  return (
    <div className="contact-us-container">
      <h1 className="contact-us-title">Contact Us</h1>
      
      {/* QR Codes Section */}
      <div className="qr-codes-section">
        <div className="qr-code">
          <img src="/qr-1.jpeg" alt="QR Code 1" className="qr-image" />
        </div>
        <div className="qr-code">
          <img src="/qr-2.jpeg" alt="QR Code 2" className="qr-image" />
        </div>
      </div>
      
      {/* Contact Information */}
      <div className="contact-info">
        <EmailIcon className="contact-icon" />
        <a href="mailto:silvestru@mayaiq.com" className="contact-email">silvestru@mayaiq.com</a>
      </div>
      
      {/* Social Media Links */}
      <div className="social-media-section">
        <a href="https://www.instagram.com/businesswithmaya?igsh=ZDI2dzN6OXQ2eDY5" target="_blank" rel="noopener noreferrer" className="social-media-link">
          <InstagramIcon className="social-icon" />
          Instagram
        </a>
        <a href="http://linkedin.com/in/silvestrumacoveiciuc" target="_blank" rel="noopener noreferrer" className="social-media-link">
          <LinkedInIcon className="social-icon" />
          LinkedIn
        </a>
        <a href="https://x.com/busineswithmaya?s=21" target="_blank" rel="noopener noreferrer" className="social-media-link">
          <TwitterIcon className="social-icon" />
          X
        </a>
      </div>
    </div>
  );
}

export default ContactUs;
