import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import backend from "../../api";
import { CircularProgress, Grid, Card, CardMedia, CardContent, Typography } from "@mui/material";
import {useParams} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import sessionstorage from "sessionstorage";


function SellerDetails() {
  const { user } = useSelector((state) => state.auth);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const {id } = useParams();
  console.log(id)


  const navigateTo = useNavigate();

  function MoveToInbox(id) {
    sessionstorage.setItem('navBtn', "Chats")
    localStorage.setItem('sellerId', id)

    navigateTo('/inbox')
  }


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await backend.get('/api/user/all');
        const filteredUsers = response?.data?.data?.filter(s => s?._id === id);
        setUsers(filteredUsers);
      } catch (error) {
        console.error("Failed to fetch sellers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return loading ? (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <CircularProgress />
    </div>
  ) : (
    <Grid container spacing={2} style={{ padding: "20px" }}>
      {users?.map((user) => (
        <Grid item xs={12} sm={6} md={3} key={user?._id}>
          <Card>
            <CardMedia
              component="img"
              height="140"
              image={user?.img}
              alt={user?.fullname}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {user?.fullname}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {user?.description}
              </Typography>
              <Typography variant="body2" color="text.primary">
                Location: {user?.location}
              </Typography>
              <Typography variant="body2" color="text.primary">
                Email: {user?.email}
              </Typography>
            </CardContent>
            <a onClick={()=>MoveToInbox(user?._id)}>Chat Now</a>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default SellerDetails;
