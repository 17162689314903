import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import backend from "../../api";
import "./User.css";
import { CircularProgress, Grid, Card, CardMedia, CardContent, Typography, Button } from "@mui/material";
import sessionstorage from "sessionstorage";
import {useNavigate} from "react-router-dom";
import { Skeleton } from "@mui/material";
import { Tooltip } from "@mui/material";



function Users() {
  const { user } = useSelector((state) => state.auth);
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(false);
  const LoggedInUserRole = user?.role;

  let filterRole;
  
  const navigateTo = useNavigate();

  function MoveToInbox(id) {
    sessionstorage.setItem('navBtn', "Chats")
    localStorage.setItem('sellerId', id)

    navigateTo('/inbox')
  }

  useEffect(() => {
    if(LoggedInUserRole=='seller') {
      filterRole='buyer'
    } else if (LoggedInUserRole=='buyer') {
      filterRole='seller'
    }
    const fetchSellers = async () => {
      try {
        setLoading(true);
        const response = await backend.get('/api/user/all');
        const filteredSellers = response?.data?.data?.filter(s => s?.role === filterRole);
        setSellers(filteredSellers);
      } catch (error) {
        console.error("Failed to fetch sellers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSellers();
  }, []);

  return loading ? (
    <Grid container spacing={4} justifyContent="center">
      {Array.from(new Array(8)).map((_, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Card className="user-card">
            <Skeleton variant="rectangular" height={200} />
            <CardContent>
              <Skeleton variant="text" height={30} width="80%" />
              <Skeleton variant="text" height={20} width="60%" />
              <Skeleton variant="text" height={20} width="40%" />
              <Skeleton variant="text" height={20} width="50%" />
            </CardContent>
            <div className="user-card-action">
              <Skeleton variant="rectangular" height={40} width="50%" />
            </div>
          </Card>
        </Grid>
      ))}
    </Grid> ): (
    <Grid container spacing={2} style={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
  {sellers.map((seller) => (
    <Grid item xs={12} sm={6} md={3} key={seller._id} style={{ transition: 'transform 0.5s',  '&:hover': { transform: 'scale(1.05)' } }}>
      <Card style={{ height: '100%',borderRadius:"20px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: "#fafafa", boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
      <CardMedia
  component="img"
  height="200"
  image={"/bot-a.png"}
  alt={seller.fullname}
  className="user-card-media"
  loading="lazy"
/>

        <CardContent>
          <Typography gutterBottom variant="h5" component="div" style={{ color: "#333", fontWeight: 'bold' }}>
            {seller.fullname}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{ marginBottom: '10px' }}>
            {seller.description.slice(0,25)}...
          </Typography>
          <Typography variant="body2" color="text.primary" style={{ marginBottom: '5px' }}>
            Location: {seller.location}
          </Typography>
          <Typography variant="body2" color="text.primary">
            Email: {seller.email}
          </Typography>
        </CardContent>
        <Tooltip title="Start a conversation with this user">
  <Button
    variant="contained"
    color="primary"
    className="chat-button"
    onClick={() => MoveToInbox(seller._id)}
  >
    Chat Now
  </Button>
</Tooltip>
      </Card>
    </Grid>
  ))}
</Grid>

  );
}

export default Users;
