// BuyerAuth.jsx

import React, { useState, useEffect } from "react";
import GoogleIcon from '@mui/icons-material/Google';
import { authActions } from "../../redux-store";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import backend from "../../api";
import { useNavigate } from "react-router-dom";
import DangerousIcon from "@mui/icons-material/Dangerous";
import CheckIcon from '@mui/icons-material/Check';
import GoogleMapReact from "google-map-react";
import MyGreatPlace from "../Profile/Mapview/my_great_place";
import axios from 'axios';

import './Auth.css';

const label = { inputProps: { 'aria-label': 'Size switch demo' } };

let role = "seller";

function BuyerAuth() {

    const [width, setWidth] = useState(window.innerWidth);
    const [state, setState] = useState("signin");
    const { user } = useSelector((state) => state.auth);
    const [email, setEmail] = useState("");
    const [fullname, setFullName] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState();
    const [location, setLocation] = useState();
    const [description, setDescription] = useState();
    
    const [uploadBrochure, setUploadBrochure] = useState([]);
    const [brochureLoading, setBrochureLoading] = useState(false);
    const [latitude, setLatitude] = useState(56);
    const [longitude, setLongitude] = useState(56);

    const defaultProps = {
      center: {
        lat: latitude,
        lng: longitude,
      },
      zoom: 4,
    };
    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    const extractFileNames = (name) => {
      // Split the name by "/" and take the last part as the file name
      const parts = name?.split('/');
      return (parts[parts?.length - 1]);
  
  };

    const updateDimensions = () => {
      setWidth(window.innerWidth);
    };
    useEffect(() => {
      window.addEventListener('resize', updateDimensions);
      return () => window.removeEventListener('resize', updateDimensions);
    }, []);


    const uploadMedia = async (files, name) => {
      const isValid = Array.from(files).every((file) => file.type === "image/png");
      console.log(files)
      if (!isValid) {
        alert("File format not supported");
        return;
      }
  
      setBrochureLoading(true);
      try {
        const formData = new FormData();
        Array.from(files).forEach((file) => formData.append("files", file));
         console.log(formData)
        const response = await axios.post("https://secret-journey-87277-02643b796295.herokuapp.com/api/upload", formData)
        console.log(response)
  
        if (name === "uploadBrochure") {
          const updatedFiles = response.data.data.map((d) => ({ ...d, key: d.name }));
          setUploadBrochure(prev => [...prev, ...updatedFiles]);
        }
        // toast.success("Files uploaded successfully");
      } catch (error) {
        alert(error.response?.data?.message || error.message || "An error occurred");
      } finally {
        setBrochureLoading(false);
      }
    };
    const handleFileChange = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        uploadMedia(e.target.files, e.target.name);
      }
    };
  
    const handleIconClick = () => {
      document.getElementById('formFile').click();
    };
  
    const handleRemoveFile = (index) => {
      setUploadBrochure(current => current.filter((_, i) => i !== index));
    };

    const loginSubmitHandler = async (e) => {
      e.preventDefault();
      setLoading(true)
      const isLogin = await dispatch(authActions.login(email, password));
      // console.log(dispatch(authActions.login(email, password)))
      
      if (isLogin) {
         
          // handleLogout()
          // await getProjectById();
          setLoading(false)  
       navigateTo("/");
      } 
      if (!isLogin) {
        setLoading(false)  
        alert("Wrong Credentials");
        
      }
    };

    const img = uploadBrochure[0]?.imgurl;

    const SignUpHandler = async (e) => {
      e.preventDefault();
      setLoading(true)
      try {
        if(email !="" && email!=null && email !=undefined &&
        password !="" && password!=null && password !=undefined
        ) {
        if(email.includes("@")  && password.length>5) {
        const isSignup = await dispatch(
          authActions.signUp(email, password, fullname, role, img, location, description, longitude, latitude)
        );
        if (isSignup) {
         setLoading(false)
         
          console.log(isSignup)
          // setState(4)
          navigateTo("/");
      }
        if (!isSignup) {
          setLoading(false)
          alert("Wrong credentials");
        }
      
    }
      else {
        setLoading(false)
        alert('Enter correct email & password length>5')
      } 
      } else {
        setLoading(false)
        alert('Enter correct email & password length>5')
      } 
      } catch (e) {
        setLoading(false)
        alert("Network Error");
      }
    };
  
    const ForgotHandler = async (e) => {
      e.preventDefault();
      setLoading(true)
      try {
        const isforgot = await dispatch(authActions.forgotPassword(email));
         if (isforgot) {
          setLoading(false)
          alert('Password sent on email')
         } else {
          setLoading(false)
          alert('Enter valid registered email')
         }
     }  catch (err) {
      setLoading(false)
        alert('Network error')
      }
      }



    const boxStyles = {
        // marginTop: "1rem",
        background: "white",
        padding: "40px",
        height: "auto",
        width: width>800?"max-content":"auto",
        borderRadius: "16px",
        boxShadow: "0 4px 8px rgba(16, 24, 40, 0.1)",
      };

  return loading ? (
    <div className="loading-container">
      <CircularProgress />
    </div>
  ) : (
    <section className="auth-section">
      {state === "signin" ? (
        <div className="auth-container">
          <div className="logo">
            <a href="/">
              <img src="/logo-orange.png" alt="logo" />
            </a>
          </div>

          <h1 className="auth-title">Login to Business Portal</h1>
          <p className="auth-subtitle">Sign in to MayaIQ</p>


          <div className="text-with-lines">
            <span>OR</span>
          </div>

          <h1 className="auth-input-label">Email</h1>
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Enter your email"
            className="auth-input"
          />

          <h1 className="auth-input-label">Password</h1>
          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Enter your password"
            className="auth-input"
          />

          <div className="remember-forgot">
           
            <div className="forgot-password">
              <button onClick={() => setState('forgot')} className="forgot-btn">
                Forgot password
              </button>
            </div>
          </div>

          <button onClick={(e) => loginSubmitHandler(e)} className="primary-btn">
            Sign in
          </button>

          <p>
            Don't have an account?
            <button onClick={() => setState("signup")} className="toggle-auth-btn">
              Sign up
            </button>
          </p>
        </div>
      ) : state === "signup" ? (
        <div className="auth-container">
          <div className="logo">
            <a href="/">
              <img src="/logo-orange.png" alt="logo" />
            </a>
          </div>

          <h1 className="auth-title">Signup to Business Portal</h1>
          <p className="auth-subtitle">Sign up to MayaIQ</p>

        

          <div className="text-with-lines">
            <span>OR</span>
          </div>

          <h1 className="auth-input-label">Email</h1>
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Enter your email"
            className="auth-input"
          />

          <h1 className="auth-input-label">Password</h1>
          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Enter your password"
            className="auth-input"
          />

          <h1 className="auth-input-label">Full Name</h1>
          <input
            onChange={(e) => setFullName(e.target.value)}
            type="fullname"
            placeholder="Enter your name"
            className="auth-input"
          />

          <h1 className="auth-input-label">Location Address</h1>
          <input
            onChange={(e) => setLocation(e.target.value)}
            type="location"
            placeholder="Enter your location"
            className="auth-input"
          />

          <h1 className="auth-input-label">Description</h1>
          <input
            onChange={(e) => setDescription(e.target.value)}
            type="description"
            placeholder="Describe yourself and your services"
            className="auth-input"
          />

          <div className="upload-section">
            {!brochureLoading && uploadBrochure.length === 0 && (
              <div className="upload-label">
                <span>Upload Image</span>
              </div>
            )}
            {brochureLoading && <CircularProgress size={10} className="loading-spinner" />}
            {uploadBrochure.length > 0 && (
              <Box className="uploaded-files">
                {uploadBrochure.map((file, i) => (
                  <React.Fragment key={i}>
                    <span>{extractFileNames(file?.imgurl).slice(0, 5)}...</span>
                    <DangerousIcon className="remove-icon" onClick={() => handleRemoveFile(i)} />
                  </React.Fragment>
                ))}
              </Box>
            )}

            <input
              onChange={handleFileChange}
              accept=".png"
              name="uploadBrochure"
              className="form-control"
              type="file"
              id="formFile"
              multiple
              style={{ display: 'none' }}
            />

            {!brochureLoading && uploadBrochure.length === 0 ? (
              <a
                className="upload-button"
                onClick={handleIconClick}
              >
                <img src="/icons/add-white.png" alt="uploadIcons" />
              </a>
            ) : !brochureLoading ? (
              <span className="upload-success">Uploaded Successfully</span>
            ) : null}
          </div>

          <h4>Choose location</h4>
          <input
            type="number"
            value={latitude}
            onChange={(e) => setLatitude(e.target.value)}
            placeholder="Enter latitude"
            className="auth-input small-input"
          />
          <input
            type="number"
            value={longitude}
            onChange={(e) => setLongitude(e.target.value)}
            placeholder="Enter longitude"
            className="auth-input small-input"
          />

          <div className="map-section">
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyD-vOMAxfW_gBBGZFieaqdR6ncKw9i4XHA" }}
              defaultCenter={{
                lat: latitude,
                lng: longitude
              }}
              defaultZoom={defaultProps.zoom}
            >
              <MyGreatPlace
                lat={latitude}
                lng={longitude}
                text={"A"} // You can replace "A" with something related to your listing like an icon or a label
              />
            </GoogleMapReact>
          </div>
          <p className="terms-text">
            By creating an account, you agree to the <a href="#" className="terms-link">Terms of Service</a> and <a href="#" className="terms-link">Privacy Policy.</a>
          </p>
          <button onClick={(e) => SignUpHandler(e)} className="primary-btn">
            Sign up
          </button>

          <p>
            Have an account?
            <button onClick={() => setState("signin")} className="toggle-auth-btn">
              Sign in
            </button>
          </p>
        </div>
      ) : (
        <div className="auth-container">
          <h1 className="auth-title">Forgot your password</h1>
          <h1 className="auth-input-label">Email</h1>
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Enter your email"
            className="auth-input"
          />
          <button onClick={() => ForgotHandler()} className="primary-btn">
            Send Message
          </button>

          <p>
            Already know password?
            <button onClick={() => setState("signin")} className="toggle-auth-btn">
              Sign in
            </button>
          </p>
        </div>
      )}
    </section>
  );
}

export default BuyerAuth;
