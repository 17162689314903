import React, { useState, useEffect } from "react";
import { CircularProgress, TextField, Grid, Paper, Button, Typography, Box, InputLabel, MenuItem, FormControl, Select, OutlinedInput, ListItemText, Checkbox } from "@mui/material";
import DangerousIcon from "@mui/icons-material/Dangerous";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from '@mui/icons-material/Delete';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import { useDispatch, useSelector,shallowEqual } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import backend from "../../api";

import './FileUpload.css';


const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1400 },
    items: 3,
 
  },

  desktop: {
    breakpoint: { max: 1399, min: 1024 },
    items: 3,
 
  },
  tablet: {
    breakpoint: { max: 1023, min: 600 },
    items: 1,
   
  },
  mobile: {
    breakpoint: { max: 599, min: 0 },
    items: 1,
  
  }
};


const FileUpload= () => {
  const [uploadBrochure, setUploadBrochure] = useState([]);
  const [brochureLoading, setBrochureLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  //@ts-ignore
  const [storedFiles, setStoredFiles] = useState([]);
  //@ts-ignore
	const { user } = useSelector((state) => state.auth);
  const userID = user._id



  const extractFileNames = (name) => {
      // Split the name by "/" and take the last part as the file name
      const parts = name?.split('/');
      return (parts[parts?.length - 1]);

  };

  

  // console.log(SavedfileNames)

  const uploadMedia = async (files, name) => {
   

    setBrochureLoading(true);
    try {
      const formData = new FormData();
      Array.from(files).forEach((file) => formData.append("files", file));
     
      const response = await axios.post("https://secret-journey-87277-02643b796295.herokuapp.com/api/upload", formData)
      console.log(response)

      if (name === "uploadBrochure") {
        const updatedFiles = response.data.data.map((d) => ({ ...d, key: d.name }));
        setUploadBrochure(prev => [...prev, ...updatedFiles]);
      }
      // toast.success("Files uploaded successfully");
    } catch (error) {
      toast.error(error.response?.data?.message || error.message || "An error occurred");
    } finally {
      setBrochureLoading(false);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      uploadMedia(e.target.files, e.target.name);
    }
  };

  const handleRemoveFile = (index) => {
    setUploadBrochure(current => current.filter((_, i) => i !== index));
  };



  const handleSave = async (e) => {
    e.preventDefault();
    
    const Files = [...storedFiles, ...uploadBrochure.map(file => file.imgurl).filter(Boolean)]; // Spread both arrays to ensure a flat structure
    
    const body = { files: Files };
   
    console.log(body); 
    try {
      const response = await backend.put(`/api/user/${userID}`, body);
      console.log(response);
     
      if(response?.data)
      { alert("Data saved successfully");
        window.location.reload();
      }
    
    } catch (error) {
      console.error("Failed to post data to server", error);
      toast.error("Failed to save data");
    }
  };


  console.log(storedFiles)
  
  const getUserDetails = async () => {
  
    try {
      setLoadingFiles(true)
      const response = await backend.put(`/api/user/${userID}`);
      setStoredFiles(response?.data?.data?.files)
      setLoadingFiles(false)

    } catch (error) {
      console.error("Failed to get data from server", error);
      
    }
  };


  // Function placeholders
  useEffect(() => { getUserDetails(); }, []);

  const DeleteFile = async (name) => {
    // Assuming storedFiles is an array of file names and you want to remove the file with the given name
    const newFiles = storedFiles.filter(file => file !== name); // Change this to !== to remove the file
    const UserId = user._id;
    setLoadingFiles(true); // Assuming setLoading is a function to manage loading state
  
    // Corrected body object to use proper object notation
    const body = {
      files: newFiles,
    };
  
    try {
      // Include method to send the body with the request
      const response = await backend.put(`/api/user/${UserId}`, body); // Pass body as the second argument to the PUT request
      if (response.status === 200 && response.data) {
				window.location.reload();
			}
      // You might want to do something with the response here, e.g., update UI or state
  
      setLoading(false);
    } catch (error) {
      console.error(error);
      // Uncomment or modify the toast error message as needed
      // toast.error("Error saving story");
    }
  };
  
  const handleRetrain = async () => {
    setLoading(true);
		try {
			const response = await backend.post(`/api/query/retrain`, { query:'Hi', files: storedFiles, EmptyVector: null });
			if (response.status === 200 && response.data) {
        setLoading(false);
				alert("Sucessfully updated")
			}
		} catch (error) {
			console.error("Failed to retrieve data from the server", error);
		}
	};



    	
  return loading? <div
  style={{
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    flexDirection: "column",
    alignItems: "center",
  }}
>
 
  <CircularProgress />
</div>:(
    <Grid container style={{ display:"flex", padding:'1rem', justifyContent:"center"}}>
      <Paper elevation={3} style={{width:"100%", padding: "1rem", }}>
        <Paper style={{ padding: "1rem", marginTop: "1rem" }} elevation={3}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ marginTop: { xs: "2rem", sm: "2rem", md: "2rem" } }}>
              <h3 style={{ marginBottom: "1rem", fontFamily:"sans-serif,manrope" }}>Upload Image</h3>
              <input
                onChange={handleFileChange}
                accept="image/png, image/jpeg"
                name="uploadBrochure"
                className="form-control"
                type="file"
                id="formFile"
                multiple
              />
              {brochureLoading && <CircularProgress size={10} />}
              {uploadBrochure?.length > 0 && (
                <Box sx={{ backgroundColor: "#e9ecef", fontSize: "12px", width: "70%" }}>
                  {uploadBrochure?.map((file, i) => (
                    <React.Fragment key={i}>
                      <span>{file?.key}</span>
                      <DangerousIcon onClick={() => handleRemoveFile(i)} />
                    </React.Fragment>
                  ))}
                </Box>
              )}
            </Grid>
          
          </Grid>
        </Paper>
        <Grid container>
          <Grid item xs={3} sm={3} md={2}>
            <Button onClick={(e)=>handleSave(e)} variant="contained" style={{ backgroundColor: "navy", margin: "2rem 0", color: "white", height: "40px" }}>Save</Button>
            <ToastContainer />
          </Grid>
        </Grid>

 
          {storedFiles?.map(file =>(
            loadingFiles? <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100vh",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>:
          
        
        <Grid item xs={3} sm={3} md={3} style={{height:"70px", borderRadius:"10px", paddingLeft:"1rem"}} className="fileBox">
      <div style={{
        display: "flex",
        flexDirection: "row",
        // justifyContent: "space-between", // changed to space-between to align items as in the image
        alignItems: "center",
        cursor:'pointer',
        
      }}>
        <a href={file} target="_blank" style={{flexGrow: 1, textDecoration:"none", color:"navy" }}>
        <p style={{ fontWeight: "600"}}>
        {extractFileNames(file).slice(0, 10)}...
        </p>
        </a>
        <DeleteIcon className="delete-icon" onClick={() => DeleteFile(file)} />
      </div>

   
    </Grid>


    
          ))}
           
            
           <Carousel
           showDots={false}
           responsive={responsive}
           autoPlay={true}
           autoPlaySpeed={2000}
           transitionDuration={500}
           infinite={true}
           swipeable={true}
          //  beforeChange={handleCarouselChange} // Capture the current index
          
         >
          {storedFiles?.map(file =>(
          <img src={file} style={{height:"100px", width:"100px"}}/>))}
          </Carousel>

        </Paper>
        </Grid>
        
  )
}

export default  FileUpload;