// src/components/Assistant/Assistant.jsx

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Configuration, OpenAIApi } from "openai";
import { Helmet } from "react-helmet";
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';
import backend from "../../../../api";

import { Grid, CircularProgress, Typography } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-toastify";
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';

// ================== Styled Components ================== //

// Floating Chat Now Button
const ChatNowButton = styled(motion.button)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  background-color: #00c6ff; /* Replace with your brand color */
  border: none;
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: #0072ff; /* Darker shade on hover */
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }
`;

// Popup Chat Container
const PopupChatContainer = styled(motion.div)`
  position: fixed;
  bottom: 100px;
  right: 30px;
  width: 350px;
  max-width: 90%;
  height: 500px;
  background-color: #2c2c54; /* Replace with your brand color */
  border-radius: 15px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  z-index: 1000;

  @media (max-width: 768px) {
    height: 80vh;
    width: 90%;
  }
`;

// Chat Header
const ChatHeader = styled.div`
  padding: 12px 16px;
  background-color: #1f1f3a; /* Replace with your brand color */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// Live Indicator
const LiveIndicator = styled.div`
  display: flex;
  align-items: center;
`;

// Live Circle Icon
const LiveCircle = styled(CircleIcon)`
  color: ${props => (props.isLightOn ? '#28a745' : '#6c757d')};
  font-size: 12px;
  margin-right: 5px;
  animation: blink 1s infinite;

  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
`;

// Close Button
const CloseButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 4px;
  
  &:hover {
    color: #ff6b6b;
  }

  &:focus {
    outline: none;
  }
`;

// Chat Content
const ChatContent = styled.div`
  flex: 1;
  padding: 16px;
  background-color: #2c2c54; /* Replace with your brand color */
  color: #ffffff;
  overflow-y: auto;
`;

// Chat Message Container
const ChatMessage = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
`;

const UserBubble = styled.div`
  max-width: 70%;
  padding: 10px 14px;
  background-color: #007bff; /* User message background color */
  color: #ffffff;
  border-radius: 18px;
  margin-left: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;

  /* Rounded corners */
  border-top-right-radius: 0;

  /* Responsive font size */
  @media (max-width: 480px) {
    padding: 8px 12px;
    font-size: 14px;
  }
`;

// Bot Message Styling
const BotBubble = styled.div`
  max-width: 70%;
  padding: 10px 14px;
  background-color: #e5e5ea; /* Bot message background color */
  color: #000000;
  border-radius: 18px;
  margin-right: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;

  /* Rounded corners */
  border-top-left-radius: 0;

  /* Responsive font size */
  @media (max-width: 480px) {
    padding: 8px 12px;
    font-size: 14px;
  }

  /* Dark mode adjustments */
  /* Uncomment if you support dark mode
  background-color: #3a3a3a;
  color: #ffffff;
  */
`;



// Chat Input Container
const ChatInputContainer = styled.div`
  display: flex;
  padding: 12px 16px;
  background-color: #1f1f3a; /* Replace with your brand color */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;

// Chat Input Field
const ChatInput = styled.input`
  flex: 1;
  padding: 8px 12px;
  border: none;
  border-radius: 20px;
  background-color: #3a3a68; /* Replace with your brand color */
  color: #ffffff;
  font-size: 14px;
  outline: none;

  ::placeholder {
    color: #cccccc;
  }
`;

// Send Button
const SendButton = styled.button`
  margin-left: 8px;
  padding: 8px 16px;
  background: linear-gradient(45deg, #00c6ff, #0072ff); /* Replace with your brand color */
  color: #ffffff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 600;
  transition: background 0.3s, transform 0.3s;
  font-size: 14px;

  &:hover {
    background: linear-gradient(45deg, #0056b3, #003f7f);
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }

  &:disabled {
    background: #555555;
    cursor: not-allowed;
  }
`;

// Typing Indicator Dots
const TypingDots = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #1f1f3a; /* Replace with your brand color */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;

// Single Dot
const Dot = styled(motion.div)`
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #ffffff;
  border-radius: 50%;
`;

// ================== Component ================== //

function Assistant() {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [isLightOn, setIsLightOn] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const chatContentRef = useRef(null);

  // Toggle for Live Indicator
  useEffect(() => {
    const interval = setInterval(() => {
      setIsLightOn(prev => !prev);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Fetch Sellers Data
  useEffect(() => {
    const fetchSellers = async () => {
      try {
        setLoading(true);
        const response = await backend.get('/api/user/all');
        const filteredSellers = response?.data?.data?.filter(s => s?.role === 'seller');
        setSellers(filteredSellers);
      } catch (error) {
        console.error("Failed to fetch sellers:", error);
        toast.error("Failed to fetch sellers data.");
      } finally {
        setLoading(false);
      }
    };

    fetchSellers();
  }, []);

  // Scroll to Bottom on New Message
  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  const scrollToBottom = () => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    const userMessage = inputText.trim();
    if (userMessage === "") return;

    const prompt = `Represent yourself as Maya Assistant. User Query is: ${userMessage}\n\nData: ${JSON.stringify(sellers)}\n\nPrevious Chat: ${JSON.stringify(messages)}\n\nPlease provide a detailed response. If the response contains any MongoDB results like IDs, image links, schemas, or AWS S3 links, automatically create a user profile with it and show it in the chatbot.
    Maya cna only help businesses finding clients and clients finding business and it has not external integrations If anyone asks about you then tell them Investing in Maya offers the opportunity to be part of a forward-thinking company that leverages AI to revolutionize business operations and customer interactions. With its innovative technology, strategic market focus, and strong potential for scalability and profitability, Maya represents a compelling investment prospect in the rapidly evolving AI landscape. Its same for all category of people.
    `;

    const newMessage = {
      content: userMessage,
      isUser: true,
    };

    setMessages(prev => [...prev, newMessage]);
    setInputText("");
    setIsTyping(true);

    sendBotMessage(prompt);
  };

  const sendBotMessage = async (userMessage) => {
    setLoading(true);
    const configuration = new Configuration({
      apiKey: "sk-proj-mPSY4Zt4fYHKNlzbwAJCT3BlbkFJdVZW9Tf70HGdOk95rKVK", // Use environment variable for security
    });
    const openai = new OpenAIApi(configuration);

    try {
      const result = await openai.createChatCompletion({
        model: "gpt-4-turbo",
        temperature: 0.75,
        messages: [{ role: "user", content: userMessage }],
      });

      const botContent = result?.data?.choices[0]?.message?.content;

      if (botContent) {
        // Detect if bot response contains MongoDB results like IDs, image links, schema, or AWS S3 links
        const profileData = parseBotResponse(botContent);
        if (profileData) {
          // Create user profile
          const profile = await createUserProfile(profileData);
          if (profile) {
            // Append profile info to bot message
            const enhancedBotContent = `${botContent}\n\n**User Profile Created:**\n- **Name:** ${profile.name}\n- **ID:** ${profile.id}\n- **Profile Link:** [View Profile](${profile.profileLink})\n- **Profile Image:** ![Profile Image](${profile.profileImage})`;
            const botMessage = {
              content: enhancedBotContent,
              isUser: false,
            };
            setMessages(prev => [...prev, botMessage]);
          } else {
            // If profile creation failed, send original bot message
            const botMessage = {
              content: botContent,
              isUser: false,
            };
            setMessages(prev => [...prev, botMessage]);
          }
        } else {
          const botMessage = {
            content: botContent,
            isUser: false,
          };
          setMessages(prev => [...prev, botMessage]);
        }
      }
      setLoading(false);
      setIsTyping(false);
    } catch (error) {
      console.error("Error fetching bot response:", error);
      toast.error("Failed to fetch bot response.");
      setLoading(false);
      setIsTyping(false);
    }
  };

  const parseBotResponse = (content) => {
    // Example parsing logic: look for specific patterns
    // Adjust based on actual response format
    const idPattern = /ID:\s*(\w+)/i;
    const imagePattern = /(https?:\/\/\S+\.(?:png|jpg|jpeg|gif))/i;
    const schemaPattern = /Schema:\s*([\s\S]*?)\n\n/i;
    const s3LinkPattern = /(https:\/\/s3-[^ ]+)/i;

    const idMatch = content.match(idPattern);
    const imageMatch = content.match(imagePattern);
    const schemaMatch = content.match(schemaPattern);
    const s3LinkMatch = content.match(s3LinkPattern);

    if (idMatch && s3LinkMatch) {
      return {
        id: idMatch[1],
        image: imageMatch ? imageMatch[1] : null,
        schema: schemaMatch ? schemaMatch[1] : null,
        s3Link: s3LinkMatch[1],
      };
    }

    return null;
  };

  const createUserProfile = async (profileData) => {
    try {
      const response = await backend.post('/api/user/profile', profileData);
      if (response?.data?.success) {
        return response.data.profile;
      } else {
        console.error("Failed to create user profile:", response.data.message);
        toast.error("Failed to create user profile.");
        return null;
      }
    } catch (error) {
      console.error("Error creating user profile:", error);
      toast.error("Error creating user profile.");
      return null;
    }
  };

  const renderBotMessage = (content) => {
    // Process content to display any images or links
    // For example, if there's an image link, display the image
    const s3Link = extractS3Link(content);
    const profileLink = extractProfileLink(content);
    const profileImage = extractImageLink(content);

    return (
      <>
        {s3Link && <img src={s3Link} alt="S3 Content" style={{ width: "150px", height: "150px", borderRadius: "8px", marginTop: "8px" }} />}
        {profileLink && <a href={profileLink} target="_blank" rel="noopener noreferrer" style={{ color: "#00c6ff", textDecoration: "underline" }}>View Profile</a>}
        {profileImage && <img src={profileImage} alt="Profile" style={{ width: "100px", height: "100px", borderRadius: "50%", marginTop: "8px" }} />}
      </>
    );
  };

  const extractS3Link = (message) => {
    const s3LinkPattern = /https:\/\/s3-[^ ]+\.(?:png|jpg|jpeg|gif)/g;
    const matches = message.match(s3LinkPattern);
    return matches ? matches[0] : null;
  };

  const extractProfileLink = (message) => {
    const profileLinkPattern = /https?:\/\/\S+/g;
    const matches = message.match(profileLinkPattern);
    // Assuming profile links contain '/sellers/'
    if (matches) {
      return matches.find(link => link.includes('/sellers/')) || null;
    }
    return null;
  };

  const extractImageLink = (message) => {
    const imagePattern = /(https?:\/\/\S+\.(?:png|jpg|jpeg|gif))/i;
    const matches = message.match(imagePattern);
    return matches ? matches[0] : null;
  };

  const renderTypingIndicator = () => {
    return (
      <TypingDots>
        <Dot
          animate={{ y: [0, -8, 0] }}
          transition={{ duration: 0.6, repeat: Infinity }}
        />
        <Dot
          animate={{ y: [0, -8, 0] }}
          transition={{ duration: 0.6, delay: 0.2, repeat: Infinity }}
        />
        <Dot
          animate={{ y: [0, -8, 0] }}
          transition={{ duration: 0.6, delay: 0.4, repeat: Infinity }}
        />
      </TypingDots>
    );
  };

  // ================== Render ================== //

  return (
    <>
      <Helmet>
        <title>MayaIQ AI Assistant</title>
      </Helmet>

      {/* Floating Chat Now Button */}
      <AnimatePresence>
        {!isChatOpen && (
          <ChatNowButton
            onClick={() => setIsChatOpen(true)}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            aria-label="Open Chat Assistant"
          >
            <ChatIcon style={{ color: "#ffffff", fontSize: "28px" }} />
          </ChatNowButton>
        )}
      </AnimatePresence>

      {/* Popup Chat Window */}
      <AnimatePresence>
        {isChatOpen && (
          <PopupChatContainer
            initial={{ opacity: 0, scale: 0.8, y: 50 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.8, y: 50 }}
            transition={{ duration: 0.3 }}
          >
            <ChatHeader>
              <Typography variant="h6">Maya Assistant</Typography>
              <LiveIndicator>
                <LiveCircle isLightOn={isLightOn} />
                <Typography variant="caption">Live</Typography>
              </LiveIndicator>
              <CloseButton onClick={() => setIsChatOpen(false)} aria-label="Close Chat Assistant">
                <CloseIcon />
              </CloseButton>
            </ChatHeader>

            <ChatContent ref={chatContentRef}>
              <AnimatePresence>
                {messages.length === 0 && !loading && !isTyping && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                  >
                    <ChatMessage>
                      <Avatar alt="Bot" src="/bot-a.png" />
                      <BotBubble>
                        Hi! I'm Maya, your AI Assistant. How can I help you today?
                      </BotBubble>
                    </ChatMessage>
                  </motion.div>
                )}
                {messages.map((message, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: message.isUser ? 50 : -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: message.isUser ? 50 : -50 }}
                  >
                    <ChatMessage>
                      {message.isUser ? (
                        <>
                <PersonIcon style={{ color: "#007bff", marginRight: "10px", fontSize: "30px" }} />
                <UserBubble>
                            {message.content}
                          </UserBubble>
                        </>
                      ) : (
                        <>
                          <Avatar alt="Bot" src="/bot-a.png" />
                          <BotBubble>
                            <pre style={{
                              fontFamily: 'Arial, sans-serif',
                              fontSize: '14px',
                              lineHeight: '1.4',
                              color: 'inherit',
                              whiteSpace: 'pre-wrap',
                              wordWrap: 'break-word',
                              margin: 0,
                            }}>
                              {message.content}
                            </pre>
                            {renderBotMessage(message.content)}
                          </BotBubble>
                        </>
                      )}
                    </ChatMessage>
                  </motion.div>
                ))}
                {isTyping && renderTypingIndicator()}
              </AnimatePresence>
            </ChatContent>

            <ChatInputContainer>
              <ChatInput
                type="text"
                value={inputText}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Type your message..."
                disabled={loading}
                aria-label="Chat input"
              />
              <SendButton onClick={handleSendMessage} disabled={loading || inputText.trim() === ""}>
                Send
              </SendButton>
            </ChatInputContainer>
          </PopupChatContainer>
        )}
      </AnimatePresence>
    </>
  );
}

export default Assistant;
