// Nav.jsx

import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../redux-store";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import PersonIcon from '@mui/icons-material/Person';
import "./Nav.css"; // Ensure this CSS file is correctly imported

function Nav() {
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const ProjectID = localStorage.getItem("projectID");

  const handleLogout = async () => {
    localStorage.clear();
    navigateTo("/");
    await dispatch(authActions.logout());
    toast.success("Signed out successfully");
  };

  const navigateToDashboard = () => {
    navigateTo(`/dashboard/`);
  };

  const handleHomeClick = () => {
    navigateTo("/account");
  };

  const toggleAuthPopup = () => {
    setOpen(!open);
  };

  return (
    <header className="navbar">
      <ToastContainer />
      <div className="navbar-container">
        {/* Logo Section */}
        <div className="logo" onClick={handleHomeClick}>
          <img
            src={user?.img ? user.img : "logo-orange.png"}
            alt="Logo"
            className="logo-image"
          />
        </div>

        {/* Navigation Links */}
        <nav className="nav-links">
          <a href="/#how-it-works" className="nav-link">
            How it Works
          </a>
          <a href="/#features" className="nav-link">
            Features
          </a>
          <a href="/pricing" className="nav-link">
            Pricing
          </a>
          <a href="/#faq" className="nav-link">
            FAQ
          </a>
       
          {user && (
            <a href="/dashboard" className="nav-link">
              Dashboard
            </a>
          )}
        </nav>

        {/* User Actions */}
        <div className="user-actions">
          {user ? (
            <div className="user-icon" onClick={navigateToDashboard}>
              <PersonIcon className="person-icon" />
            </div>
          ) : (
            <div className="auth-buttons">
              <a href="/buyerauth" className="auth-button buyer-login">
                Login to Client
              </a>
              <a href="/sellerauth" className="auth-button seller-login">
                Login to Business
              </a>
            </div>
          )}
        </div>

      
      </div>
    </header>
  );
}

export default Nav;
