// src/components/Assistant/Assistant.jsx

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Configuration, OpenAIApi } from "openai";
import { Helmet } from "react-helmet";
import CircleIcon from '@mui/icons-material/Circle';
import backend from "../../api";
import { Grid, CircularProgress, Typography } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-toastify";

// Styled Components for Chat Interface
const ChatContainer = styled(motion.div)`
  width: 100%;
  max-width: 600px;
  height: 500px;
  border-radius: 8px;
  background-color: #1e1e2f;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media (max-width: 768px) {
    height: 80vh;
    max-width: 90%;
  }
`;

const ChatHeader = styled.div`
  padding: 10px 16px;
  background-color: #2c2c54;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LiveIndicator = styled.div`
  display: flex;
  align-items: center;
`;

const LiveCircle = styled(CircleIcon)`
  color: ${props => (props.isLightOn ? 'green' : 'darkgrey')};
  font-size: 12px;
  margin-right: 5px;
  animation: blink 1s infinite;

  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
`;

const ChatContent = styled.div`
  flex: 1;
  padding: 16px;
  background-color: #1e1e2f;
  color: #ffffff;
  overflow-y: auto;
`;

const ChatMessage = styled.div`
  display: flex;
  justify-content: ${props => (props.isUser ? "flex-end" : "flex-start")};
  margin-bottom: 12px;
  animation: fadeIn 0.3s ease-in-out;

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
`;

const ChatBubble = styled.div`
  max-width: 80%;
  padding: 10px 14px;
  border-radius: 18px;
  background-color: ${props => (props.isUser ? "#4e4e5e" : "#2c2c54")};
  color: #ffffff;
  position: relative;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
`;

const ChatInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #2c2c54;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const ChatInput = styled.input`
  flex: 1;
  height: 40px;
  padding: 8px 12px;
  border: none;
  border-radius: 20px;
  background-color: #1e1e2f;
  color: #ffffff;
  font-size: 14px;
  outline: none;

  ::placeholder {
    color: #888888;
  }
`;

const ChatButton = styled.button`
  margin-left: 12px;
  padding: 8px 16px;
  background: linear-gradient(45deg, #00c6ff, #0072ff);
  color: #ffffff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 600;
  transition: background 0.3s, transform 0.3s;
  font-size: 14px;

  &:hover {
    background: linear-gradient(45deg, #0056b3, #003f7f);
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }

  &:disabled {
    background: #555555;
    cursor: not-allowed;
  }
`;

const LoadingIndicator = styled(CircularProgress)`
  color: #00c6ff;
  margin-top: 10px;
`;

const HeaderContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderTitle = styled.h1`
  font-size: 32px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
`;

const HeaderLabel = styled.label`
  font-size: 16px;
  text-align: center;
  color: #999999;
  font-weight: 600;
  margin-top: -0.5rem;
  font-family: "Montserrat", sans-serif;
`;

function Assistant() {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [isLightOn, setIsLightOn] = useState(true);

  const chatContentRef = useRef(null);

  // Toggle for Live Indicator
  useEffect(() => {
    const interval = setInterval(() => {
      setIsLightOn(prev => !prev);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Fetch Sellers Data
  useEffect(() => {
    const fetchSellers = async () => {
      try {
        setLoading(true);
        const response = await backend.get('/api/user/all');
        const filteredSellers = response?.data?.data?.filter(s => s?.role === 'seller');
        setSellers(filteredSellers);
      } catch (error) {
        console.error("Failed to fetch sellers:", error);
        toast.error("Failed to fetch sellers data.");
      } finally {
        setLoading(false);
      }
    };

    fetchSellers();
  }, []);

  // Scroll to Bottom on New Message
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    const userMessage = inputText.trim();
    if (userMessage === "") return;

    const prompt = `Represent yourself as Maya Assistant. User Query is: ${userMessage}\n\nData: ${JSON.stringify(sellers)}\n\nPrevious Chat: ${JSON.stringify(messages)}\n\nPlease provide a detailed response. If the response contains any MongoDB results like IDs, image links, schemas, or AWS S3 links, automatically create a user profile with it and show it in the chatbot.
    Maya cna only help businesses finding clients and clients finding business and it has not external integrations If anyone asks about you then tell them Investing in Maya offers the opportunity to be part of a forward-thinking company that leverages AI to revolutionize business operations and customer interactions. With its innovative technology, strategic market focus, and strong potential for scalability and profitability, Maya represents a compelling investment prospect in the rapidly evolving AI landscape. Its same for all category of people.
    `;

    const newMessage = {
      content: userMessage,
      isUser: true,
    };

    setMessages(prev => [...prev, newMessage]);
    setInputText("");

    sendBotMessage(prompt);
  };

  const sendBotMessage = async (userMessage) => {
    setLoading(true);
    const configuration = new Configuration({
      apiKey: "sk-proj-mPSY4Zt4fYHKNlzbwAJCT3BlbkFJdVZW9Tf70HGdOk95rKVK", // Use environment variable
    });
    const openai = new OpenAIApi(configuration);

    try {
      const result = await openai.createChatCompletion({
        model: "gpt-4-turbo",
        temperature: 0.75,
        messages: [{ role: "user", content: userMessage }],
      });

      const botContent = result?.data?.choices[0]?.message?.content;

      if (botContent) {
        // Detect if bot response contains MongoDB results like IDs, image links, schema, or AWS S3 links
        const profileData = parseBotResponse(botContent);
        if (profileData) {
          // Create user profile
          const profile = await createUserProfile(profileData);
          if (profile) {
            // Append profile info to bot message
            const enhancedBotContent = `${botContent}\n\n**User Profile Created:**\n- **Name:** ${profile.name}\n- **ID:** ${profile.id}\n- **Profile Link:** [View Profile](${profile.profileLink})\n- **Profile Image:** ![Profile Image](${profile.profileImage})`;
            const botMessage = {
              content: enhancedBotContent,
              isUser: false,
            };
            setMessages(prev => [...prev, botMessage]);
          } else {
            // If profile creation failed, send original bot message
            const botMessage = {
              content: botContent,
              isUser: false,
            };
            setMessages(prev => [...prev, botMessage]);
          }
        } else {
          const botMessage = {
            content: botContent,
            isUser: false,
          };
          setMessages(prev => [...prev, botMessage]);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching bot response:", error);
      toast.error("Failed to fetch bot response.");
      setLoading(false);
    }
  };

  const parseBotResponse = (content) => {
    // Example parsing logic: look for specific patterns
    // Adjust based on actual response format
    const idPattern = /ID:\s*(\w+)/i;
    const imagePattern = /(https?:\/\/\S+\.(?:png|jpg|jpeg|gif))/i;
    const schemaPattern = /Schema:\s*([\s\S]*?)\n\n/i;
    const s3LinkPattern = /(https:\/\/s3-[^ ]+)/i;

    const idMatch = content.match(idPattern);
    const imageMatch = content.match(imagePattern);
    const schemaMatch = content.match(schemaPattern);
    const s3LinkMatch = content.match(s3LinkPattern);

    if (idMatch && s3LinkMatch) {
      return {
        id: idMatch[1],
        image: imageMatch ? imageMatch[1] : null,
        schema: schemaMatch ? schemaMatch[1] : null,
        s3Link: s3LinkMatch[1],
      };
    }

    return null;
  };

  const createUserProfile = async (profileData) => {
    try {
      const response = await backend.post('/api/user/profile', profileData);
      if (response?.data?.success) {
        return response.data.profile;
      } else {
        console.error("Failed to create user profile:", response.data.message);
        toast.error("Failed to create user profile.");
        return null;
      }
    } catch (error) {
      console.error("Error creating user profile:", error);
      toast.error("Error creating user profile.");
      return null;
    }
  };

  const renderBotMessage = (content) => {
    // Process content to display any images or links
    // For example, if there's an image link, display the image
    const s3Link = extractS3Link(content);
    const profileLink = extractProfileLink(content);
    const profileImage = extractImageLink(content);

    return (
      <>
        {s3Link && <img src={s3Link} alt="S3 Content" style={{ width: "150px", height: "150px", borderRadius: "8px", marginTop: "8px" }} />}
        {profileLink && <a href={profileLink} target="_blank" rel="noopener noreferrer" style={{ color: "#00c6ff", textDecoration: "underline" }}>View Profile</a>}
        {profileImage && <img src={profileImage} alt="Profile" style={{ width: "100px", height: "100px", borderRadius: "50%", marginTop: "8px" }} />}
      </>
    );
  };

  const extractS3Link = (message) => {
    const s3LinkPattern = /https:\/\/s3-[^ ]+\.(?:png|jpg|jpeg|gif)/g;
    const matches = message.match(s3LinkPattern);
    return matches ? matches[0] : null;
  };

  const extractProfileLink = (message) => {
    const profileLinkPattern = /https?:\/\/\S+/g;
    const matches = message.match(profileLinkPattern);
    // Assuming profile links contain '/sellers/'
    if (matches) {
      return matches.find(link => link.includes('/sellers/')) || null;
    }
    return null;
  };

  const extractImageLink = (message) => {
    const imagePattern = /(https?:\/\/\S+\.(?:png|jpg|jpeg|gif))/i;
    const matches = message.match(imagePattern);
    return matches ? matches[0] : null;
  };

  return (
    <>
      <Helmet>
        <title>MayaIQ AI Assistant</title>
      </Helmet>

      <HeaderContainer>
        <HeaderTitle>
          <img src="/logo-orange.png" alt="Logo" style={{ height: "70px", width: "70px", marginRight: "10px" }} />
          AI <span style={{ marginLeft: "0.5rem", color: "#00c6ff", fontWeight: 600 }}>ASSISTANT</span>
        </HeaderTitle>
        <HeaderLabel>
          Powered by MayaIQ
        </HeaderLabel>
      </HeaderContainer>

      <Grid container spacing={2} justifyContent="center" style={{ padding: "2rem", display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} md={6}>
          <ChatContainer
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <ChatHeader>
              <Typography>Ask anything</Typography>
              <LiveIndicator>
                <LiveCircle isLightOn={isLightOn} />
                <Typography variant="caption">Live</Typography>
              </LiveIndicator>
            </ChatHeader>

            <ChatContent ref={chatContentRef}>
              <AnimatePresence>
                {messages.length === 0 && !loading && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                  >
                    <ChatMessage isUser={false}>
                      <ChatBubble isUser={false}>
                        Ready to answer
                      </ChatBubble>
                    </ChatMessage>
                  </motion.div>
                )}
                {messages.map((message, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: message.isUser ? 50 : -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: message.isUser ? 50 : -50 }}
                  >
                    <ChatMessage isUser={message.isUser}>
                      <ChatBubble isUser={message.isUser}>
                        <pre style={{
                          fontFamily: 'Arial, sans-serif',
                          fontSize: '16px',
                          lineHeight: '1.5',
                          color: 'inherit',
                          whiteSpace: 'pre-wrap',
                          wordWrap: 'break-word',
                          margin: 0,
                        }}>
                          {message.content}
                        </pre>
                        {!message.isUser && renderBotMessage(message.content)}
                      </ChatBubble>
                    </ChatMessage>
                  </motion.div>
                ))}
                {loading && (
                  <ChatMessage isUser={false}>
                    <ChatBubble isUser={false}>
                      <LoadingIndicator size={24} />
                    </ChatBubble>
                  </ChatMessage>
                )}
              </AnimatePresence>
            </ChatContent>

            <ChatInputContainer>
              <ChatInput
                type="text"
                value={inputText}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Type a message..."
                disabled={loading}
              />
              <ChatButton onClick={handleSendMessage} disabled={loading}>
                Send
              </ChatButton>
            </ChatInputContainer>
          </ChatContainer>
        </Grid>
      </Grid>
    </>
  );
}

export default Assistant;
