// src/components/Footer/Footer.jsx

import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import "./Footer.css"; // Updated CSS

const Footer = () => {
    const [width, setWidth] = useState(window.innerWidth);

    const updateDimensions = () => {
      setWidth(window.innerWidth);
    };

    useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <Box component="footer" className="footer">
          <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
              
              {/* Logo and Copyright */}
              <Grid item xs={12} sm={4} className="footer-logo-section">
                <motion.img 
                  src="/logo-orange.png" 
                  alt="Logo" 
                  className="footer-logo"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                />
                <Typography variant="body2" className="footer-copy">
                  © {new Date().getFullYear()} MayaIQ. All Rights Reserved.
                </Typography>
              </Grid>

              {/* Navigation Links */}
              <Grid item xs={12} sm={4} className="footer-links-section">
                <Grid container spacing={2} justifyContent="center">
                
                  <Grid item>
                    <Link href="#privacy" className="footer-link">
                      Privacy Policy
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#terms" className="footer-link">
                      Terms of Service
                    </Link>
                  </Grid>
                </Grid>
              </Grid>

              {/* Social Media Icons */}
              <Grid item xs={12} sm={4} className="footer-social-section">
                <Grid container spacing={2} justifyContent={width > 700 ? "flex-end" : "center"}>
                  <Grid item>
                    <motion.a 
                      href="http://linkedin.com/in/silvestrumacoveiciuc" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="footer-social-link"
                      whileHover={{ scale: 1.2, color: "#0e76a8" }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <LinkedInIcon fontSize="large" />
                    </motion.a>
                  </Grid>
                  <Grid item>
                    <motion.a 
                      href="https://www.instagram.com/businesswithmaya?igsh=ZDI2dzN6OXQ2eDY5" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="footer-social-link"
                      whileHover={{ scale: 1.2, color: "#C13584" }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <InstagramIcon fontSize="large" />
                    </motion.a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
    );
};

export default Footer;
