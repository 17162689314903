import React, { Component} from 'react';
import PropTypes from 'prop-types'; // ES6

import shouldPureComponentUpdate from 'react-pure-render/function';

import {greatPlaceStyle} from './my_great_place_styles.js';
import PlaceIcon from '@mui/icons-material/Place';

export default class MyGreatPlace extends Component {
  static propTypes = {
    text: PropTypes.string
  };

  static defaultProps = {};

  shouldComponentUpdate = shouldPureComponentUpdate;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <PlaceIcon style={{color:"red", fontSize:"25px"}}/>
      
    );
  }
}