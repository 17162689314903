import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { CircularProgress } from "@mui/material";
import { Configuration, OpenAIApi } from "openai";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import backend from "../../../api";
import { SET_RESPONSE_TEXT, setEditorText, setChatHistoryId } from "../../../redux-store/auth/actions";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import NorthIcon from '@mui/icons-material/North';

// Keyframes for animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

// Styled Components
const ChatContainer = styled.div`
  width: 100%;
  height: 90%;
  background-color: #f3f4f6;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: ${fadeIn} 0.5s ease-out;
`;

const ChatHeader = styled.div`
  padding: 16px;
  background: linear-gradient(45deg, #007bff, #6610f2);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LiveIndicator = styled.div`
  display: flex;
  align-items: center;
`;

const LiveCircle = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${props => (props.isActive ? 'green' : 'darkgrey')};
  border-radius: 50%;
  margin-right: 8px;
  animation: ${props => (props.isActive ? blink : 'none')} 1s infinite;
`;

const ChatContent = styled.div`
  flex: 1;
  padding: 16px;
  background-color: #ffffff;
  overflow-y: auto;
  scroll-behavior: smooth;

  /* Custom scrollbar */
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;
  }
`;

const ChatMessage = styled.div`
  display: flex;
  justify-content: ${props => (props.isUser ? "flex-end" : "flex-start")};
  margin-bottom: 12px;
  animation: ${fadeIn} 0.3s ease-out;
`;

const ChatBubble = styled.div`
  max-width: 80%;
  padding: 12px 16px;
  border-radius: 20px;
  background-color: ${props => (props.isUser ? "#007bff" : "#e0e0e0")};
  color: ${props => (props.isUser ? "#ffffff" : "#333333")};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  align-items: flex-start;
`;

const Avatar = styled.div`
  width: 30px;
  height: 30px;
  background: ${props => (props.isUser ? "linear-gradient(45deg, #007bff, #6610f2)" : "#8E8E8E")};
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  margin-right: 12px;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

const MessageText = styled.pre`
  font-family: 'Arial, sans-serif';
  font-size: 16px;
  line-height: 1.5;
  color: inherit;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
`;

const ChatInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #f3f4f6;
  border-top: 1px solid #ddd;
`;

const ChatInput = styled.textarea`
  flex: 1;
  min-height: 40px;
  max-height: 100px;
  padding: 10px 14px;
  border: 1px solid #ccc;
  border-radius: 20px;
  resize: none;
  font-size: 14px;
  font-family: 'Arial, sans-serif';
  outline: none;
  background-color: #ffffff;
  color: #333333;

  ::placeholder {
    color: #999999;
  }

  &:focus {
    border-color: #007bff;
  }
`;

const SendButton = styled.button`
  margin-left: 12px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const VoiceButton = styled.button`
  margin-left: 12px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  svg {
    color: #007bff;
    transition: color 0.3s, transform 0.3s;
  }

  &:hover svg {
    color: #0056b3;
    transform: scale(1.2);
  }
`;

const LoadingIndicator = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #007bff;
  }
`;

function ChatBox() {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [displayText, setDisplayText] = useState("");
  const navigateTo = useNavigate();
  const [typing, setTyping] = useState(false);
  const [response, setResponse] = useState('');
  const [Msgstate, setMsgState] = useState(false);
  const lastItem = messages[messages.length - 1];
  const [loading, setLoading] = useState(false);
  const [creatingNewChat, setCreatingNewChat] = useState(false);

  const lastMessage = "Plz regenerate this answer" + (lastItem?.content || "");

  const [copiedTextForEditor, setCopiedText] = useState('');
  const [textareaRows, setTextareaRows] = useState(3);

  const dispatch = useDispatch()

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const {RouteId1, RouteId2} = useParams();
  // console.log(RouteId1)

  // const chatId = RouteId2?.id;
  // const chatId = localStorage.getItem("chatId")

  const promptText = useSelector((state) => state.promptText.promptText);
  const responseText = useSelector((state) => state.responseText.responseText);
  const chatId = useSelector((state) => state.chatId.chatId);

  useEffect(() => {
    if (responseText) {
      sendBotMessage(responseText)
      // AddToEditor()
    }
  }, [responseText]);

  const text =
    "  Greetings! Nice to meet you in the digital world. I'm here to give you a virtual hand and address any questions or concerns you may have. My knowledge and skills are at your disposal. So don't hesitate to ask me something. How can I be of service to you today?";

  const { user } = useSelector((state) => state.auth);

  const [open, setOpen] = React.useState(false);
  const handleOpenAuth= () => {setOpen(true)};

  const handleCloseAuth = () => {
    setOpen(false);
  };

  useEffect(() => {
    if(!user) {
      setOpen(true)
    }

    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      setDisplayText((prevText) => prevText + text[currentIndex]);
      currentIndex++;
      if (currentIndex === text.length) {
        clearInterval(typingInterval);
      }
    }, 10);

    return () => {
      clearInterval(typingInterval);
    };
  }, []);

  const chatContentRef = useRef(null);

  // const Copyprompt = localStorage.getItem('Copyprompt');
  // useEffect(() => {
  //   if(Copyprompt) {
  //     setMessages([
  //       {
  //         content: Copyprompt,
  //         isUser: true
  //       }
  //     ])
  //     sendBotMessage(Copyprompt)
  //     localStorage.removeItem('Copyprompt')
  //   }
  // }, []);

  const copyResponse = (msg) => {
    navigator.clipboard.writeText(msg);
    toast.success("Copied successfully")
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  };

  const handleInputChange = (event) => {
    const lines = event.target.value.split('\n').length
    setTextareaRows(lines);
    setInputText(event.target.value);
  };

  const inputRef = useRef();

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  let newMessage;

  const handleSendMessage = () => {
    if (inputText.trim() !== "") {
      setTyping(true);
      if(user.role === "buyer") {
        newMessage = {
          content: inputText.trim(),
          isUser: true,
        };
      } else {
        newMessage = {
          content: inputText.trim(),
          isUser: false,
        };
      }
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputText("");
      sendBotMessage(newMessage.content);
    } else {
      toast.warning("Please enter a message");
    }
  };

  const [levelList, setlevelList] = useState("Portuguese");
  function handleSelectChange(event) {
    setlevelList(event.target.value);
  }

  const [workflows, setWorkflows] = useState([]);
  const email = user.email;

  const [userData, setUserData] = useState([]);
  const [seller, setSellers] = useState([]);

  const Copyprompt = localStorage.getItem('Copiedprompt');
  const sellerId = localStorage.getItem('sellerId');

  useEffect(() => {
    const fetchSellers = async () => {
      try {
        setLoading(true);
        const response = await backend.get('/api/user/all');
        const filteredSellers = response?.data?.data?.filter(s => s?._id === sellerId);
        setSellers(filteredSellers);
      } catch (error) {
        console.error("Failed to fetch sellers:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchBuyer = async () => {
      try {
        setLoading(true);
        const response = await backend.get('/api/user/all');
        const filterData = response?.data?.data?.filter(s => s?._id === user._id);
        setUserData(filterData);
      } catch (error) {
        console.error("Failed to fetch buyers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBuyer();
    fetchSellers();
  }, [user._id, sellerId]);

  console.log(seller)

  // useEffect(() => {
  //   if (userData && Copyprompt && userData.apiKey) {
  //     setMessages([
  //       {
  //         content: Copyprompt,
  //         isUser: true,
  //       },
  //     ]);

  //     console.log(userData);
  //     sendBotMessage(Copyprompt);
  //     localStorage.removeItem('Copiedprompt');
  //   }
  // }, [userData, Copyprompt]);

  // useEffect(() => {
  //   if (Copyprompt !== undefined || Copyprompt !== null) {
  //     setInputText(Copyprompt);
  //   }
  // }, [Copyprompt]);

  // useEffect(() => {
  //   if (userData && promptText && userData.apiKey) {

  //     const userMessage = {
  //       content: promptText,
  //       isUser: true,
  //     };

  //     setMessages((prevMessages) => [...prevMessages, userMessage]);

  //     console.log(userData);
  //     sendBotMessage(promptText);
  //     // localStorage.removeItem('Copiedprompt');
  //     dispatch(setPromptText(null));

  //   }
  // }, [userData, promptText]);

  useEffect(() => {
    if (promptText) {
      setInputText(promptText);
    }
  }, [promptText]);

  useEffect(() => {
    if(chatId ) {
      navigateTo(`/inbox/${chatId}`)
    }
  },[chatId])

  // console.log(userData)

  const pitch = localStorage.getItem('pitch');
  console.log(pitch)

  const sendBotMessage = async (userMessage) => {
    setResponse('Msg sent');
  };

  useEffect(() => {
    const getsChat = async () => {
      const userId = user?._id
      try {
        // setLoading(true);
        const response = await backend.put(`/chats/${chatId}`);
          // setLoading(false);
          setMessages(response?.data?.data.chat);
      } catch (error) {
        console.error(error);
        // toast.error("Error saving story");
      }
    };

    getsChat();
  },[chatId]);

  console.log(chatId);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // First try-catch block
        // setLoading(true);
        const response = await backend.put(`/chats/${chatId}`); // Assuming backend is set up and axios is your HTTP client library
        // setLoading(false);
        console.log(response?.data);
        setMessages(response?.data?.data?.chat);
        setMsgState(true);
      } catch (error) {
        console.error(error);
        // toast.error("Error saving story");
      }
    };

    fetchData();
    // Call the fetchData function to execute the API call when the component mounts
  }, [chatId]);

  const sellerName = seller[0]?.fullname;
  const buyerName = userData[0]?.fullname;

  console.log(buyerName)
  useEffect(() => {
    const saveChat = async () => {
      const userId = user?._id;

      try {
        // Second try-catch block
        const body = {
          chat: messages,
          SellerId: sellerId,
          sellerName: sellerName,
          buyerName: buyerName
        };
        console.log(chatId);

        // setLoading(true);
        const response = await backend.put(`/chats/${chatId}`, body);
        if (response) {
          // setLoading(false);
          console.log(response);
        }
      } catch (error) {
        console.error(error);
        // toast.error("Error saving story");
      }
    };

    const createNewChat = async () => {
      const userId = user?._id;
      try {
        // Second try-catch block
        const body = {
          UserId: userId,
          chat: messages,
          SellerId: sellerId,
          sellerName: sellerName,
          buyerName: buyerName
        };

        // setLoading(true);
        const response = await backend.post(`/chats`, body);
        if (response) {
          // setLoading(false);
          console.log(response)
          const RouteID = response?.data?.data?._id
          // localStorage.setItem("chatId", RouteID)
          if(RouteID) {
            await dispatch(setChatHistoryId(RouteID))
            // await UpdateProjectById(RouteID)
            // navigateTo(`/inbox/${RouteID}`)
          }
        }
      } catch (error) {
        console.error(error);
        // toast.error("Error saving story");
      }
    };

    if(response !== '' ) {
      if(chatId) {
        saveChat();
      }
      else {
        createNewChat()
      }
    }
  }, [response, chatId, dispatch, messages, sellerId, sellerName, buyerName, user]);

  const AddToEditor = (msg) => {
    dispatch(setEditorText(msg));
  }

  const {
    transcript,
    listening,
    resetTranscript,
    finalTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(() => {
    if (finalTranscript) { // Check if listening is off and transcript is fully completed
      setInputText(finalTranscript)
      resetTranscript();
      handleSendMessage();
    }
  }, [finalTranscript, resetTranscript]); // Add finalTranscript and transcript to the dependency array

  console.log(finalTranscript);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const saveChat = async () => {
    const userId = user._id
    setLoading(true)
    try {
      const body = {
        UserId: userId,
        projectId:"test",
        chat: messages,
      };

      const response = await backend.post("/chats", body);
      // setLoading(false);
      console.log(response)
      const RouteID = response?.data?.data?._id
      // localStorage.setItem("chatId", RouteID)
      if(RouteID) {
        await dispatch(setChatHistoryId(RouteID))
        // await UpdateProjectById(RouteID)
        // navigateTo(`/inbox/${RouteID}`)
      }

      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
      // toast.error("Error saving story");
    }
  };

  // console.log(transcript)
  const messageStyles = {
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    lineHeight: '1.5',
    color: 'black',
    whiteSpace: 'pre-wrap', // allow wrapping of long lines
    wordWrap: 'break-word' // break long words
  };

  return (
    <ChatContainer>
      {/* <ChatHeader><img className="headerLogo" src="/logo-small.png"/></ChatHeader> */}

      <ChatContent ref={chatContentRef}>
        {messages.length === 0 ? (
          <ChatMessage isUser={false}>
            <ChatBubble>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <MessageText style={{
                  ...messageStyles,
                  flex: "1",
                  minWidth: "0",
                  paddingTop:"0.22rem"
                }}>
                  {user.role === "buyer" ? 
                    (seller[0]?.fullname ? `Seller Name: ${seller[0]?.fullname}` : "Please select a seller from the sellers page") : 
                    "Please select a chat"}
                </MessageText>
              </div>
            </ChatBubble>
          </ChatMessage>
        ) : null}

        {messages.map((message, index) => (
          <ChatMessage key={index} isUser={message.isUser}>
            <ChatBubble isUser={message.isUser}>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <Avatar isUser={message.isUser}>
                  {message.isUser ? "B" : "AI"}
                </Avatar>
                <MessageText style={{
                  ...messageStyles,
                  flex: "1",
                  minWidth: "0",
                  paddingTop:"0.22rem"
                }}>
                  {message.content}
                </MessageText>
              </div>
            </ChatBubble>
          </ChatMessage>
        ))}

        {loading && (
          <ChatMessage isUser={false}>
            <ChatBubble isUser={false}>
              <LoadingIndicator>
                <CircularProgress size={24} />
              </LoadingIndicator>
            </ChatBubble>
          </ChatMessage>
        )}
      </ChatContent>

      <ChatInputContainer>
        <ChatInput
          value={inputText}
          onChange={handleInputChange}
          placeholder="Ask any question to seller...."
          onKeyPress={handleKeyPress}
          ref={inputRef}
          rows={textareaRows}
        />

        <SendButton onClick={handleSendMessage} disabled={loading}>
          <NorthIcon />
        </SendButton>

        <VoiceButton onClick={() => {
          if (listening) {
            SpeechRecognition.stopListening();
          } else {
            SpeechRecognition.startListening({ continuous: true });
          }
        }}>
          {listening ? <MicOffIcon /> : <MicIcon />}
        </VoiceButton>
      </ChatInputContainer>
    </ChatContainer>
  );
}

export default ChatBox;
